import * as yup from 'yup';

// Add method
yup.addMethod(yup.string, 'stripEmptyString', function () {
    return this.transform((value) => (value === '' ? '' : value));
});

const SUPPORTED_FORMATS = [
    "application/pdf",
  ];

export const generateValidationSchema = (fileFields) => {
    const schema = {
        numero_documento: yup.string().required('required'),
        tipo_compania: yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        tipo_documento: yup.number().required('required'),
        razon_social: yup.string().required('required'),
        direccion_principal: yup.string().required('required'),
        codigo_postal: yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        telefono_fijo: yup.string().required('required'),
        telefono_celular: yup.string().required('required'),
        correo_empresarial: yup.string().email('Correo electrónico no es válido.').required('required'),
        pais: yup.string().stripEmptyString().required('required'),
        departamento: yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        ciudad: yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        ciudad_nombre: yup.string().required('required'),
        matricula_mercantil: yup.string().required('required'),
        sagrilaft: yup.number().required('required'),
        sarlaft: yup.number().required('required'),
        prevension_lavados_activos: yup.number().required('required'),
        regimen_tributario: yup.number().required('required'),
        responsable_iva: yup.number().required('required'),
        nombre_rep: yup.string().required('required'),
        tipo_documento_rep:  yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        numero_de_identidad_rep:  yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        correo_electronico_rep: yup.string().email('Correo electrónico no es válido.').required('required'),
        numero_telefonico_rep:  yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        persona_expuesta:  yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        tamano_empresa:  yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        entidad_financiera: yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        numero_cuenta: yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        tipo_cuenta:  yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        nombre_contacto_financiero: yup.string().required('required'),
        cargo_contacto_financiero: yup.string().required('required'),
        telefono_contacto_financiero:  yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        correo_electronico_financiero: yup.string().email('Correo electrónico no es válido.').required('required'),
        nombre_contacto_comercial: yup.string().required('required'),
        cargo_contacto_comercial: yup.string().required('required'),
        telefono_contacto_comercial:  yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        correo_electronico_contacto_comercial: yup.string().email('Correo electrónico no es válido.').required('required'),
        expbienes_servicios: yup.string().required('required'),
        nombre_contacto_lg: yup.string().required('required'),
        cargo_contacto_lg: yup.string().required('required'),
        telefono_contacto_lg:  yup.number().transform((value) => Number.isNaN(value) ? null : value ).required('required'),
        correo_electronico_contacto_lg: yup.string().email('Correo electrónico no es válido.').required('required'),
        check_habeas_data: yup.boolean().required('required'),
        ...fileFields.reduce((acc, field) => {
            acc[`iddir_${field.iddir}`] = yup.mixed()
            .test("required", "required", (file) => {
                if (file) return true;
                return false;
            }).test(
                "fileFormat",
                "Only the following formats are accepted:  .pdf",
                value => value && SUPPORTED_FORMATS.includes(value.type)
              );
            return acc;
        }, {}),
        
    }

    return yup.object(schema);
}