import React from "react";
import { useNavigate } from "react-router-dom";

// * contante que controla la pantalla completa al hacer click en el boton
const expandirPantalla = () => {
  if (document.fullscreenElement) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  } else {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
  }
}



export const Headercomponent = ({
  className,
  nav_item1_Link,
  nav_item1_Text,
  nav_item2_Link,
  nav_item2_Text,
  nav_item3_Link,
  nav_item3_Text,
  role,
  target,
  text,
}) => {

  const navigate = useNavigate();
  

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light ">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item px-2">
          <button  className="btn" data-widget="pushmenu">
            <i className="fas fa-bars text-danger" />
          </button>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <a href={nav_item1_Link} className="nav-link" target="">
            {nav_item1_Text}
          </a>
        </li>
      </ul>

      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        {/* Navbar Search */}
        {/* <li className="nav-item">
              <a
                className="nav-link"
                data-widget="navbar-search"
                href="#"
                role="button"
              >
                <i className="fas fa-search" />
              </a>
              <div className="navbar-search-block">
                <form className="form-inline">
                  <div className="input-group input-group-sm">
                    <input
                      className="form-control form-control-navbar"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-navbar" type="submit">
                        <i className="fas fa-search" />
                      </button>
                      <button
                        className="btn btn-navbar"
                        type="button"
                        data-widget="navbar-search"
                      >
                        <i className="fas fa-times" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </li> 
    
            {/* Messages Dropdown Menu */}
        <li className="messages-menu dropdown">
          <a className="nav-link" data-toggle="dropdown" href="1">
            {/* <i class="far fa-comments"></i> */}
            {/* <span class="badge badge-danger navbar-badge">3</span> */}
          </a>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <a href="1" className="dropdown-item">
              {/* Message Start */}
              <div className="media">
                <img
                  // src={`${process.env.REACT_APP_HOST}/dist/img/Lg-logo.png`}
                  src="../../../public/dist/img/Lg-logo.png"
                  alt="User Avatar"
                  className="img-size-50 mr-3 img-circle"
                />
                <div className="media-body">
                  <h3 className="dropdown-item-title">
                    Brad Diesel
                    <span className="float-right text-sm text-danger">
                      <i className="fas fa-star" />
                    </span>
                  </h3>
                  <p className="text-sm">Call me whenever you can...</p>
                  <p className="text-sm text-muted">
                    <i className="far fa-clock mr-1" /> 4 Hours Ago
                  </p>
                </div>
              </div>

              {/* Message End */}
            </a>
            <div className="dropdown-divider" />
            <a href="1" className="dropdown-item">
              {/* Message Start */}
              <div className="media">
                <img
                  src="dist/img/user8-128x128.jpg"
                  alt="User Avatar"
                  className="img-size-50 img-circle mr-3"
                />
                <div className="media-body">
                  <h3 className="dropdown-item-title">
                    John Pierce
                    <span className="float-right text-sm text-muted">
                      <i className="fas fa-star" />
                    </span>
                  </h3>
                  <p className="text-sm">I got your message bro</p>
                  <p className="text-sm text-muted">
                    <i className="far fa-clock mr-1" /> 4 Hours Ago
                  </p>
                </div>
              </div>

              {/* Message End */}
            </a>
            <div className="dropdown-divider" />
            <a href="1" className="dropdown-item">
              {/* Message Start */}
              <div className="media">
                <img
                  src="dist/img/user3-128x128.jpg"
                  alt="User Avatar"
                  className="img-size-50 img-circle mr-3"
                />
                <div className="media-body">
                  <h3 className="dropdown-item-title">
                    Nora Silvester
                    <span className="float-right text-sm text-warning">
                      <i className="fas fa-star" />
                    </span>
                  </h3>
                  <p className="text-sm">The subject goes here</p>
                  <p className="text-sm text-muted">
                    <i className="far fa-clock mr-1" /> 4 Hours Ago
                  </p>
                </div>
              </div>

              {/* Message End */}
            </a>
            <div className="dropdown-divider" />
            <a href="1" className="dropdown-item dropdown-footer">
              See All Messages
            </a>
          </div>
        </li>

        {/* Notifications Dropdown Menu */}

        <li className="nav-item dropdown">
          <a className="nav-link" data-toggle="dropdown" href="1">
            {/* <i className="far fa-bell" /> */}
            {/* <span className="badge badge-warning navbar-badge">15</span> */}
          </a>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <span className="dropdown-item dropdown-header">
              15 Notifications
            </span>
            <div className="dropdown-divider" />
            <a href="1" className="dropdown-item">
              <i className="fas fa-envelope mr-2" /> 4 new messages
              <span className="float-right text-muted text-sm">3 mins</span>
            </a>
            <div className="dropdown-divider" />
            <a href="1" className="dropdown-item">
              <i className="fas fa-users mr-2" /> 8 friend requests
              <span className="float-right text-muted text-sm">12 hours</span>
            </a>
            <div className="dropdown-divider" />
            <a href="1" className="dropdown-item">
              <i className="fas fa-file mr-2" /> 3 new reports
              <span className="float-right text-muted text-sm">2 days</span>
            </a>
            <div className="dropdown-divider" />
            <a href="1" className="dropdown-item dropdown-footer">
              See All Notifications
            </a>
          </div>
        </li>

        {/* Boton de user */}
        <li className="nav-item">
          <button
            className="btn"
            title="Click aquí para ver ajustes de usuario"
            onClick={() => navigate('/user/profile')}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" style={{ height: '15px' }} fill='#a50034'>
              <path d="M224 0a128 128 0 1 1 0 256A128 128 0 1 1 224 0zM178.3 304l91.4 0c11.8 0 23.4 1.2 34.5 3.3c-2.1 18.5 7.4 35.6 21.8 44.8c-16.6 10.6-26.7 31.6-20 53.3c4 12.9 9.4 25.5 16.4 37.6s15.2 23.1 24.4 33c15.7 16.9 39.6 18.4 57.2 8.7l0 .9c0 9.2 2.7 18.5 7.9 26.3L29.7 512C13.3 512 0 498.7 0 482.3C0 383.8 79.8 304 178.3 304zM436 218.2c0-7 4.5-13.3 11.3-14.8c10.5-2.4 21.5-3.7 32.7-3.7s22.2 1.3 32.7 3.7c6.8 1.5 11.3 7.8 11.3 14.8l0 30.6c7.9 3.4 15.4 7.7 22.3 12.8l24.9-14.3c6.1-3.5 13.7-2.7 18.5 2.4c7.6 8.1 14.3 17.2 20.1 27.2s10.3 20.4 13.5 31c2.1 6.7-1.1 13.7-7.2 17.2l-25 14.4c.4 4 .7 8.1 .7 12.3s-.2 8.2-.7 12.3l25 14.4c6.1 3.5 9.2 10.5 7.2 17.2c-3.3 10.6-7.8 21-13.5 31s-12.5 19.1-20.1 27.2c-4.8 5.1-12.5 5.9-18.5 2.4l-24.9-14.3c-6.9 5.1-14.3 9.4-22.3 12.8l0 30.6c0 7-4.5 13.3-11.3 14.8c-10.5 2.4-21.5 3.7-32.7 3.7s-22.2-1.3-32.7-3.7c-6.8-1.5-11.3-7.8-11.3-14.8l0-30.5c-8-3.4-15.6-7.7-22.5-12.9l-24.7 14.3c-6.1 3.5-13.7 2.7-18.5-2.4c-7.6-8.1-14.3-17.2-20.1-27.2s-10.3-20.4-13.5-31c-2.1-6.7 1.1-13.7 7.2-17.2l24.8-14.3c-.4-4.1-.7-8.2-.7-12.4s.2-8.3 .7-12.4L343.8 325c-6.1-3.5-9.2-10.5-7.2-17.2c3.3-10.6 7.7-21 13.5-31s12.5-19.1 20.1-27.2c4.8-5.1 12.4-5.9 18.5-2.4l24.8 14.3c6.9-5.1 14.5-9.4 22.5-12.9l0-30.5zm92.1 133.5a48.1 48.1 0 1 0 -96.1 0 48.1 48.1 0 1 0 96.1 0z"/>
            </svg>
          </button>
        </li>

        {/* Boton de Full Screen */}
        <li className="nav-item">
          <button
            className="btn"
            data-widget="fullscreen"
            onClick={expandirPantalla}
            title="Click aquí para expandir la pantalla"
          >
            <i className="fas fa-expand-arrows-alt text-danger" style={{color: '#a50034'}} />
          </button>
        </li> 
      </ul>
    </nav>
  );
};
