import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import entranslation from './en/translation.json';
import estranslation from './es/translation.json';

i18next.use(initReactI18next).init({
  lng: 'en',
  debug: false,
  resources: {
    es: {
      translation: estranslation,
    },
    en: {
      translation: entranslation,
    },
  },
});