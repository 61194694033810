import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationMenu } from './NavigationMenu';
import { changeChildren } from '../../slices/navigation/navigationSlice';

export const ListMenus = () => {
    const dispatch = useDispatch()
    const  { menus } = useSelector( (state) => state.navigation)
    useEffect(() => {
    }, [menus])
    
    const onClickMenu = (value) =>{       
        dispatch(changeChildren(value))
        
    }
    
    const Childrens  = ({data}) => {
        return (
            <ul className="nav nav-treeview" style={{display: data.loading_children ? 'block' : 'none'}}>
                {
                    data?.loading_children && ( 
                        <NavigationMenu items={data.children}/>
                    )
                }
            </ul>
        )
    }

    return(
			<>
				{menus.map((data, index) => { 
                    const { name, icon } = data;

                    return (
                        <li className={ data.loading_children ? "nav-item" : "nav-item  menu-is-opening menu-open"} key={name}>
                            <NavLink
                                    to={name.toLowerCase() || ''}
                                    className="nav-link"
                                    onClick={() => onClickMenu(index)}
                            >
                                <span className='d-flex flex-row justify-content-between  align-items-center'>
                                    <i className={icon} />   
                                    <p className="uppercase">{name}</p>
                                    <i className={ data?.loading_children ? "fas fa-angle-down" : " fas fa-angle-left"} />
                                </span>                                    
                            </NavLink>
                            <Childrens data={data} />
                        </li>
                    )
				})}
			</>
    )
}