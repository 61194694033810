import React from 'react';
import './css/styles.css'
import './i18n/config';
import { AppRoutes } from './routes/AppRoutes';

export const App = () => {

  return (
    <>
      <AppRoutes/>
    </>
  )
}
