import React, { useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";

import { useForm, Controller } from "react-hook-form";


import { updateFilesRechazados, updateObjectFilesupd } from '../../slices/vendor/vendorThunks';
import { instanceAxiosPrivate } from '../../axios';
import LoadingPortal from '../loadings/LoadingPortal';

const UploadFile = (props) => {
    
    const dispatch = useDispatch()
    const { loading } = useSelector((state) => state.form_vendor);
    const file = useRef({});

    const { data } = props;

    const { 
        register,
        control,
        handleSubmit,
        setValue,
      } = useForm({});
    
      const onSubmit = async (values) => {
        //send to adrive and upd state
        let info = {
            iddir: data.iddir
        }        
        dispatch(updateFilesRechazados(info))
      }

      const handleChangeFile = async (e) => {
        // selection file
        const file = e.target.files[0];
        // set file on window
        const instA = new instanceAxiosPrivate();
        instA.setItemGeneral(`iddir_${data.iddir}`, file);

        if(file){
            setValue(`iddir_${data.iddir}`, instA.getItemGeneral(`iddir_${data.iddir}`)); 
        }

        dispatch(updateObjectFilesupd({
            iddir: data.iddir,
            name_dir: data.name_dir,
            name_file: file.name,
            mime: 31,
            size: file.size,
            type_document: 1
        }))
    }

    return (
        <div 
        className={'card card-danger card-outline p-3'}
        >
            { loading ? (<LoadingPortal isOpen={loading} />): ''}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='col-12 col-md-12 px-2'>
                    <label className='text-capitalize'>
                        {data.name_dir}
                    </label>
                    <Controller
                        name={`iddir_${data.iddir}`}
                        control={control}
                        render={({ field: { value, onChange, ...field } }) => (
                            <input
                                type="file" 
                                {...field}
                                {...register(`iddir_${data.iddir}`)}
                                id={`iddir_${data.iddir}`}
                                value={value?.fileName}
                                ref={file}
                                accept="application/pdf"
                                onChange={ (event) => {handleChangeFile(event)} }
                                className="form-control"
                            />
                        )}
                    />
                    { data.rechazo_documento 
                        ? <span className='text-red text-break font-weight-bold' style={{fontSize: '14px'}}>{data.rechazo_documento}</span> 
                        : <span className='text-red text-break font-weight-bold' style={{fontSize: '14px'}}>Opcional</span> 
                    }
                </div>
                <div className='col-12 col-md-12 px-2 mt-2'>
                    <button type='button' className='btn btn-danger float-right' onClick={handleSubmit(onSubmit)}>Cargar</button>   
                </div>
            </form>
        </div>
    )
}

export default UploadFile