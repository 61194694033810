import { instanceAxios, instanceAxiosPrivate } from "../../axios";
import { toast } from "react-toastify";

import {
    ActualizarForm,
    setPaisDane,
    setDepartamentoDane,
    setCiudadDane,
    setDocumentType,
    setEfectosTributarios,
    setGlosarioTipoEmpresa,
    setEfectosTributariosTarifa,
    setGlosarioTipago,
    setCodigoPostal,
    setRegimen,
    setTypedocument,
    updArrayVal,
    setDisabled,
    setFilesUpd,
    updArrayfilesVal,
    setRegimentrib,
    setDisabledNit,
    setLoading,
    setBanks,
    setRequired
} from './vendorSlice';

export const validateNit = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/vendorprocess`, {
                params: {
                    numero_documento: e
                }
            });
            
            let info = {
                origen_proveedor: null,
                //form 1
                numero_documento: data.nit,
                tipo_compania: data.id_person_type,
                tipo_documento: data.id_document_type,
                razon_social: data.name,
                direccion_principal: data.address,
                codigo_postal: data.codigo_postal,
                telefono_fijo: null,
                telefono_celular: null,
                correo_empresarial: data.mail_address,
                pais: data.codigo_pais,
                departamento: data.codigo_departamento_dane,
                ciudad: data.codigo_ciudad_dane,
                ciudad_nombre: data.city,
                matricula_mercantil: null,
                sagrilaft: null,
                sarlaft: null,
                prevension_lavados_activos: null,
                //form 2
                regimen_tributario: null,
                responsable_iva: null,
                //form 3
                nombre_rep: "",
                tipo_documento_rep: "",
                numero_de_identidad_rep: "",
                correo_electronico_rep: "",
                numero_telefonico_rep: "",
                persona_expuesta: "",
                //form 4
                tamano_empresa: "",
                entidad_financiera: data.bank_name,
                numero_cuenta: data.bank_account_type,
                tipo_cuenta: "",
                //form 5
                nombre_contacto_financiero: "",
                cargo_contacto_financiero: "",
                telefono_contacto_financiero: "",
                correo_electronico_financiero: "",
                //form 6
                nombre_contacto_comercial: data.nom_contacto,
                cargo_contacto_comercial: "",
                telefono_contacto_comercial: "",
                correo_electronico_contacto_comercial: data.commercial_email.replace(',', ''),
                expbienes_servicios: "",
                //form 7
                nombre_contacto_lg: "",
                cargo_contacto_lg: "",
                numero_telefono_contacto_lg: "",
                correo_electronico_contacto_lg: "",
                //form documentos
                iddir: data.iddir || [],

                user_id: data.user_id,
                name_dir: data.name_dir || "",
                idparent: data.idparent || ""

            }
            dispatch(ActualizarForm(info));
            dispatch(setDisabled(false));
            dispatch(setDisabledNit(true));
        } catch ({response}) {          
            toast.error(response.data.mns);
        }
    }
}
export const getTokenUser = (e) => {
    return async (dispatch, getState) => {          
        const { data } = await instanceAxios.get(`/v1/token/`, {
            params: {
                user_id: e.user_id || e
            }
        });
        const xhr = new instanceAxiosPrivate()
        xhr.setToken(data.token);
    }
}
export const getPais = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/pais`);
            let pais =  data.map(r => {
                return {
                    label: r.nombre_pais,
                    value: r.codigo_iso
                }
            });
            dispatch(setPaisDane(pais));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getDepartamento = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/departamentodane`);
            let depart =  data.map(r => {
                return {
                    label: r.nombre_departamento,
                    value: Number(r.codigo_dane_departamento)
                }
            });
            dispatch(setDepartamentoDane(depart));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getCiudad = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/ciudaddane`);
            let city =  data.map(r => {
                return {
                    label: r.nombre_ciudad,
                    value: Number(r.codigo_dane_ciudad)
                }
            });
            dispatch(setCiudadDane(city));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getDocumentType = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/documenttype`);
            let docttype =  data.map(r => {
                return {
                    label: r.doc_type_desc,
                    value: Number(r.doc_type_id)
                }
            });
            dispatch(setDocumentType(docttype));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getEfectosTributarios = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/efectostributarios`);
            let efectrib =  data.map(r => {
                return {
                    label: r.nombre_efecto_tributario,
                    value: Number(r.id_efecto_tributario)
                }
            });
            dispatch(setEfectosTributarios(efectrib));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getEfectosTributariosTarifa = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/efectostributariostarifa`);
            let efectribtarifa =  data.map(r => {
                return {
                    label: r.nombre_efecto_tributario_tarifa,
                    value: Number(r.id)
                }
            });
            dispatch(setEfectosTributariosTarifa(efectribtarifa));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getGlosarioTipoEmpresa = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/glosariotipoempresa`);
            let glotipemp =  data.map(r => {
                return {
                    label: r.nombre_tipo_empresa,
                    value: Number(r.id)
                }
            });
            dispatch(setGlosarioTipoEmpresa(glotipemp));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getGlosarioTipago = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/glosariotipago`);
            let glotippag =  data.map(r => {
                return {
                    label: r.dia_pago,
                    value: Number(r.dia_pago.split(' ')[0])
                }
            });
            dispatch(setGlosarioTipago(glotippag));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getCodigoPostal = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/codigopostal`);
            let codpostal =  data.map(r => {
                return {
                    label: `${r.nombre_codigo}`,
                    value: Number(r.id)
                }
            });
            dispatch(setCodigoPostal(codpostal));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getRegimen = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/regimen`);
            let regdata =  data.map(r => {
                return {
                    label: r.nombre_regimen,
                    value: Number(r.id)
                }
            });
            dispatch(setRegimen(regdata));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getTypedocument = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/typedocumentvendor`);
            let respdata =  data.map(r => {
                return {
                    label: r.nombre_documento,
                    value: Number(r.id)
                }
            });
            dispatch(setTypedocument(respdata));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getRegimentributario = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/regimentributario`);
            let respdata =  data.map(r => {
                return {
                    label: r.nombre_tributario,
                    value: Number(r.id)
                }
            });
            dispatch(setRegimentrib(respdata));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getBanks = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/bancos`);
            let respdata =  data.map(r => {
                return {
                    label: r.nombre_banco,
                    value: Number(r.id)
                }
            });
            dispatch(setBanks(respdata));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getRequired = (e) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await instanceAxios.get(`/v1/vendor/requerido`);
            let respdata =  data.map(r => {
                return {
                    label: r.nombre,
                    value: Number(r.id)
                }
            });
            dispatch(setRequired(respdata));  
        } catch (error) {
            toast.error(error.message);
        }
    }
}
export const getDataFiles = (e) => {
    return async (dispatch, getState) => {
        try {
            const xhr = new instanceAxiosPrivate();

            const {data} = await xhr.services.get(`/v1/vendor/fsfilesrechazado?identification=` + e);
            dispatch(setFilesUpd(data));  
        } catch ({response}) {
            toast.error(response.data.mns);
        }
    }
}
export const updateVendor = () => {
    return async (dispatch, getState) => {
        const { form } = getState().form_vendor;
        let info = [];

        //filter by key
        const items = form.iddir.filter(item => item.requerid === 'S');
        // validar si hay elementos vacios
        const elementValid = items.some(obj => !obj.name_file);

        if(elementValid) {
            toast.error("Debe cargar todos los archivos requeridos");
            dispatch(setLoading(false))
            return
        }
        
        dispatch(setLoading(true))
        await dispatch(getTokenUser(form.numero_documento));          

        for (let i = 0; i < form.iddir.length; i++) {
            const element = form.iddir[i];

            if(element.requerid === 'N' && element.name_file === null){
                info = [ ...info, { ...element, ...element} ];
                continue;
            }

            try {
                let { data } = await dispatch(uploadFileDrive(element));
                if(data) {
                    //upd
                    info = [ ...info, { ...element, ...data} ];
                }
            } catch(error) {
                toast.error(error);
                dispatch(setLoading(false))
                return
            }
        }
        dispatch(updArrayVal(info))
        try {
            await dispatch(sendForm()); 
        } catch (error) {
            toast.error(error);
            dispatch(setLoading(false))
            return
        }
    }
}

export const sendForm = () => {
    return async (dispatch, getState) => {     
        const { form } = getState().form_vendor;
        localStorage.setItem('form_vendor', JSON.stringify(form));
        const { data } = await instanceAxios.put(`/v1/vendor/updvendorprocess`, form);
        if(data){
            dispatch(setLoading(false))
            toast.success(data.msn);
            dispatch(setDisabled(true));
            setTimeout(() => {
                window.localStorage.clear();
                window.location.replace('/');
            }, 2000);
        }
    }
}

export const uploadFileDrive = (e,  nit = '', id_dir = 0) => {
    return async (dispatch, getState) => {      
        const {form} = getState().form_vendor;
        
        const xhr = new instanceAxiosPrivate()
        const formD = new FormData();
        const iddir = e.iddir || id_dir;
        const file = xhr.getItemGeneral(`iddir_${iddir }`);

        formD.append('files_drive', file);
        formD.append('parents', '15IOq9L3XCVX45G2-A6OMBIpcpP7UFydv');
        formD.append('suppliers', form.numero_documento || nit);
        formD.append('type', iddir || id_dir);
        
        const { data = {} } = await xhr.services.post(`/v1/managerFilesEvidencias`, formD );

        xhr.setItemGeneral( `${iddir}_obj`, data )
        return data;
    }
}

export const getDataToLocalStorage = () => {
    return async (dispatch, getState) => {
        try {            
            const data = JSON.parse(localStorage.getItem('form_vendor'));
            
            if (data && data.length !== null) {
                dispatch(ActualizarForm(data));
            }
        } catch (error) {
            toast.error(error);
        }
    }
}

export const updateObjectIddir = (e = {}) => {
    return async (dispatch, getState) => {
        const { form } = getState().form_vendor;

        let data = form.iddir.map((item) => (
            item.iddir === e.iddir ? { ...item, ...e } : item
        ))
        dispatch(updArrayVal(data))
        
    }
}

export const updateObjectFilesupd = (e = {}) => {
    return async (dispatch, getState) => {
        const { filesupd } = getState().form_vendor;

        let data = filesupd.map((item) => (
            item.iddir === e.iddir ? { ...item, ...e } : item
        ))
        dispatch(updArrayfilesVal(data))
        
    }
}

export const updateFilesRechazados = (e) => {
    return async (dispatch, getState) => {
        const { iddir } = e;        
        const { filesupd } = getState().form_vendor;
        const { perfil } = getState().user;
        const element = filesupd.filter(obj => obj.iddir === iddir)
        let info = element;
        try {
            dispatch(setLoading(true))
            const { data } = await dispatch(uploadFileDrive(info, perfil.identification, iddir))
            let dataupd = filesupd.filter(
                (item) => item.iddir === iddir
            ).map((item) => (
                item.iddir === iddir ? { ...item, ...data } : item
            ))
            dispatch(updateObjectFilesupd(...dataupd))
            await dispatch(sendFormFiles(iddir))            
        } catch (error) {
            dispatch(setLoading(false))
            toast.error(error.mns);
            return
        }
    }
}

export const sendFormFiles = (datau) => {
    return async (dispatch, getState) => {     
        let inf = [];
        const { filesupd } = getState().form_vendor;
        const { perfil } = getState().user;
        const xhr = new instanceAxiosPrivate()
        let dataupd = filesupd.filter((item) => item.iddir === datau)

        inf = [...inf, ...dataupd];

        const {data} = await xhr.services.post(`/v1/vendor/fsfilesupd`, inf, {
            headers: { 'Content-Type': 'application/json' } 
        }
        );  
        
        if(data){
            dispatch(setLoading(false));
            await dispatch(getDataFiles(perfil.identification));
            
            toast.success(data.msn);
            return data;
        }
        
    }
}

export const generatePDF = (obj) => {
    return async (dispatch, getState) => {     
        try {
            dispatch(setLoading(true));
            const {data} = await instanceAxios.post(`/v1/generatepdf`, obj, {
                headers: { 'Content-Type': 'application/json' },
            }); 
            if(data !== '') {
                let newTab = document.createElement('a');
                newTab.href = process.env.REACT_APP_ENDPOINT + data;
                newTab.target = "_blank";
                newTab.click();
            }     
        } catch (error) {
            toast.error(error);
            return
        }        
    }
}