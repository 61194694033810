import React from 'react';
import { Route, Routes } from 'react-router-dom'
import { Contact } from '../Contacts'
import { Profile } from '../Profile';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';

export const UserRoutes = () => {
  
  return (
    <Routes>
      <Route path='user' errorElement={<ErrorBoundary />}>
        <Route path="contact" element={<Contact />} />
        <Route path="profile" element={<Profile />} />
      </Route>
    </Routes>
  )
}