import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { instanceAxiosPrivate } from "../../axios";
import { updateUserData } from "../../slices/users/userThunks";
import LoadingPortal from '../loadings/LoadingPortal';
import { updatePerfil} from "../../slices/users/userSlice";

export const Profile = () => {
  const [show, setShow] = useState(false)
  const { perfil, loading } = useSelector( state => state.user)
  const [photo, setPhoto] = useState('')

  const dispatch = useDispatch()

  const { 
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
    setValue,
    watch
  } = useForm({});

  const onSubmit = async (values) =>{
    for (var key in values) if (key.startsWith("photo")) delete values[key];
    dispatch(updatePerfil(values));
    dispatch(updateUserData());
    setShow(false);
  }

  

  const watchShowImagePreview = watch("photo");

  const handleFileChange =  (event, r) => {
      const file = event.target.files[0];
      //guardar el archivo
      const instA = new instanceAxiosPrivate();
      instA.setItemGeneral(`photo`, file);
  
      if(file){
        setValue(`photo`, instA.getItemGeneral(`photo`)); 
      }
    }

  useEffect(() => {
    if(perfil) {
      reset({
        first_name: perfil.first_name,
        last_name: perfil.last_name,
        email: perfil.email,
        identification: perfil.identification,
      })
    }
  }, [perfil, reset])


    useEffect(() => {
      if(perfil.photo !== '' && perfil.photo !== null){
        setPhoto(`https://lh3.googleusercontent.com/u/0/d/${perfil.photo}=w1920-h974-iv1`)
      } else  {
        setPhoto(`${process.env.REACT_APP_HOST}/dist/img/user2-160x160.jpg`)
      }
    }, [perfil])

  return (
    <div className="container">
      { loading ? (<LoadingPortal isOpen={loading} />): ''}
      <div className="row pt-3">
        <div className="col-md-4">
          <div className="card card-danger card-outline">
            <div className="card-body box-profile">
              <div className="text-center">
                <img
                  className="profile-user-img img-fluid img-circle"
                  src={`https://lh3.googleusercontent.com/u/0/d/${photo}=w1920-h974-iv1`}
                  alt="User profile"
                />
              </div>
              <ul className="list-group list-group-unbordered mb-3">
                <li className="list-group-item">
                  <b>First Name</b> <span className="float-right">{perfil.first_name}</span>
                </li>
                {perfil.last_name && (
                  <li className="list-group-item">
                    <b>Last Name</b> <span className="float-right">{perfil.last_name}</span>
                  </li>
                )}
                <li className="list-group-item">
                  <b>Email</b> <span className="float-right">{perfil.email}</span>
                </li>
                <li className="list-group-item">
                  <b>Identification</b> <span className="float-right">{perfil.identification}</span>
                </li>
              </ul>

              <span className="btn btn-danger btn-block mt-3" onClick={() => setShow(true)}>
                <b>Edit profile</b>
              </span>
            </div>
          </div>
        </div>
        {
          show && (
            <div className="col-md-6">
              <div className="card card-danger card-outline">
                <div className="d-flex justify-content-between flex-row mt-2">
                <div className="w-75 text-center">
                  <h3 className="profile-username mb-3">Edit Profile</h3>
                </div>
                <div className="w-25 text-right">
                  <button 
                    type="button" 
                    className="btn bg-danger text-white mr-3"
                    onClick={() => setShow(false)}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                </div>
                <div className="card-body box-profile">
                  <div className="text-center">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div>
                      {watchShowImagePreview && (
                        <img 
                          className="profile-user-img img-fluid img-circle"
                          src={URL.createObjectURL(watchShowImagePreview)} 
                          style={{width: '150px', height: '150px'}}
                          alt="selected file preview" 
                        />
                      )}
                      </div>
                      <div>
                        <label>Photo</label>
                        <Controller
                          name={'photo'}
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, ref } }) => (
                            <>
                            <input
                              type="file" 
                              id={'photo'}
                              accept="image/jpg, image/jpeg, image/png, image/webp"
                              className='form-control'
                              onChange={ (event) => { 
                                onChange(event.target.files[0])
                                handleFileChange(event) 
                              } }
                              ref={ref}
                            />
                            { errors.photo && (
                              <p className='text-danger mb-0'>{ errors.photo.message }</p>
                            )}
                            </>
                          )}
                        />
                      </div>
                      <div>
                        <label>First Name</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><i className="far fa-user"></i></span>
                          </div>
                          <input type="text" {...register('first_name')} className="form-control"/>
                        </div>
                      </div>
                      <div>
                        <label>Last Name</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><i className="far fa-user"></i></span>
                          </div>
                          <input type="text" {...register('last_name')} className="form-control"/>
                        </div>
                      </div>
                      <div>
                        <label>Identification</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><i className="far fa-id-card"></i></span>
                          </div>
                          <input type="text" {...register('identification')} className="form-control"/>
                        </div>
                      </div>
                      <div>
                        <label>Email</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><i className="far fa-envelope"></i></span>
                          </div>
                          <input type="email" {...register('email')} className="form-control"/>
                        </div>
                      </div>
                      <div className="mt-2">
                        <button type="button" className="btn btn-danger" onClick={handleSubmit(onSubmit)}>Guardar</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        
      </div>
    </div>
  );
};
