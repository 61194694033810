import React from 'react';
import ReactDOM from 'react-dom';

const LoadingPortal = ({isOpen}) => {

    let  myModalContainer;

    if (!isOpen) return null;

    if (window !== "undefined") {
        myModalContainer = document.querySelector("#loading-root");    
    }

    return (
        isOpen &&
        ReactDOM.createPortal(
            <div 
                className="loading-overlay" 
                style={{
                    display: "block",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    width: '100%',
                    position: 'fixed',
                    height: '100%',
                    backdropFilter: 'blur(5px)',
                    zIndex: 9999,
                    justifyContent: 'center',
                    alignContent: 'center',
                    overflowY: 'hidden',
                    textAlign: 'center',
                    transition: 'all 0.5s ease-out'
                }} 
            >
                <img src={`${process.env.REACT_APP_HOST}/dist/img/icon-loading.webp`} className="w-10" alt="icon loading" />
                <br />
            </div>,
            myModalContainer
        )
    );
};

export default LoadingPortal;