import React from 'react'
import ReactDOM from 'react-dom/client'
// import reportWebVitals from './reportWebVitals';
import { store } from './stores/store'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import  {App} from './App'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
    <div id="modal" />  
    <div id="loading-root" />
      <Provider store={store}>
        <App />
        <ToastContainer  autoClose={3000}/>
      </Provider>
    </BrowserRouter>  
  // </React.StrictMode> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
